import { ModalBody } from "reactstrap";

import { ColorPicker, CustomInput } from "../../../../components";
import { AdvancedSettings } from "./components/AdvancedSettings";
import { AppThemeSelect } from "./components/AppThemeSelect";
import styles from "./index.module.scss";
import {
  APP_THEME,
  AuthProviders,
  CTA_BUTTON_STYLE,
  USER_MESSAGE_TEMPLATE,
  AUTH_PROVIDER_STATE,
  PROCESSING_LOADER_STATE,
  GET_WALLET_AUTH,
} from "../../../../utils";

export const THEMES_MAP: Record<APP_THEME, "Light" | "Dark"> = {
  [APP_THEME.LIGHT]: "Light",
  [APP_THEME.DARK]: "Dark",
};

interface AppDisplayContainerProps {
  displayName: string;
  appLogoUrl: string;
  secondaryColor: string;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  processingLoader: PROCESSING_LOADER_STATE;
  setDirectValue: (
    key: string,
    value: string | boolean | AuthProviders | string[] | USER_MESSAGE_TEMPLATE
  ) => void;
  skipFinalScreen: boolean;
  appTheme: "Light" | "Dark";
  whitelistedOrigins: string[];
  userMessageTemplate: USER_MESSAGE_TEMPLATE;
  customCssValue: string;
  hideUserProfileSection: boolean;
  hideWalletOperationDetailsSection: boolean;
  ctaButtonStyle: CTA_BUTTON_STYLE;
  fontName: string;
  fontSizeScaling: string;
  authProviders: AuthProviders;
  getWalletAuth: GET_WALLET_AUTH;
}

export const AppDisplayContainer = ({
  displayName,
  appLogoUrl,
  secondaryColor,
  onChangeHandler,
  processingLoader,
  setDirectValue,
  skipFinalScreen,
  appTheme,
  whitelistedOrigins,
  userMessageTemplate,
  customCssValue,
  hideUserProfileSection,
  hideWalletOperationDetailsSection,
  ctaButtonStyle,
  fontName,
  fontSizeScaling,
  authProviders,
  getWalletAuth,
}: AppDisplayContainerProps) => {
  const onColorChange = (color: string, id: string) => {
    setDirectValue(id, color);
  };

  const onSkipFinalScreenChange = (isOn: boolean) => {
    setDirectValue("skipFinalScreen", isOn);
  };

  const onThemeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const theme = e.target.value;
    setDirectValue("appTheme", theme);
  };

  const onWhitelistOriginChange = (value: string[]) => {
    setDirectValue("whitelistedOrigins", value);
  };

  const onUserMessageTemplateSelect = (value: USER_MESSAGE_TEMPLATE) => {
    setDirectValue("userMessageTemplate", value);
  };

  const onCustomCssChange = (value: string) => {
    setDirectValue("customCss", value);
  };

  const onHideDetailsChange = (isOn: boolean) => {
    setDirectValue("hideWalletOperationDetailsSection", isOn);
  };

  const onHideUserSectionChange = (isOn: boolean) => {
    setDirectValue("hideUserProfileSection", isOn);
  };

  const onProcessingLoaderToggle = (isOn: boolean) => {
    setDirectValue(
      "processingLoader",
      isOn ? PROCESSING_LOADER_STATE.ENABLED : PROCESSING_LOADER_STATE.DISABLED
    );
  };

  const onUserPhoneAuthenticationChange = (isOn: boolean) => {
    const value = isOn
      ? AUTH_PROVIDER_STATE.ENABLED
      : AUTH_PROVIDER_STATE.DISABLED;
    setDirectValue("authProviders", {
      ...authProviders,
      phone: {
        state: value,
      },
    });
  };

  const onCTAButtonStyleSelect = (value: CTA_BUTTON_STYLE) => {
    setDirectValue("ctaButtonStyle", value);
  };

  const setFontName = (value: string) => {
    setDirectValue("fontName", value);
  };

  const setFontSizeScaling = (value: string) => {
    setDirectValue("fontSizeScaling", value);
  };

  const onGetWalletAuthChange = (isOn: boolean) => {
    setDirectValue(
      "getWalletAuth",
      isOn ? GET_WALLET_AUTH.ENABLED_ALWAYS : GET_WALLET_AUTH.DEFAULT
    );
  };

  return (
    <ModalBody className={styles.modal_body_with_overflow}>
      <CustomInput
        id="displayName"
        description="Users will be able to see this in their wallet. Help them identify your app. If display name is not provided, application name will be used."
        onChangeHandler={onChangeHandler}
        placeholder="Public display name"
        label="Display Name"
        value={displayName}
      />
      <CustomInput
        id="appLogoUrl"
        label="App Logo"
        value={appLogoUrl}
        placeholder="Eg: https://example.com/logo.png"
        description="We recommend using a rectangular image with dimensions 1000px x 180px."
        onChangeHandler={onChangeHandler}
      />

      <AppThemeSelect onChange={onThemeChange} activeSelection={appTheme} />
      <ColorPicker
        onChange={(color: string) => {
          onColorChange(color, "secondaryColor");
        }}
        currentColor={secondaryColor || "#000"}
      />
      <AdvancedSettings
        skipFinalScreen={skipFinalScreen}
        processingLoader={processingLoader}
        onProcessingLoaderToggle={onProcessingLoaderToggle}
        onSkipFinalScreenChange={onSkipFinalScreenChange}
        whitelistedOrigins={whitelistedOrigins}
        setWhitelistedOrigins={onWhitelistOriginChange}
        onUserMessageTemplateSelect={onUserMessageTemplateSelect}
        userMessageTemplate={userMessageTemplate}
        onCustomCssChange={onCustomCssChange}
        customCssValue={customCssValue}
        hideUserProfileSection={hideUserProfileSection}
        hideWalletOperationDetailsSection={hideWalletOperationDetailsSection}
        onHideDetailsChange={onHideDetailsChange}
        onHideUserSectionChange={onHideUserSectionChange}
        ctaButtonStyle={ctaButtonStyle}
        onCTAButtonStyleSelect={onCTAButtonStyleSelect}
        fontName={fontName}
        setFontName={setFontName}
        setFontSizeScaling={setFontSizeScaling}
        fontSizeScaling={fontSizeScaling}
        authProviders={authProviders}
        onUserPhoneAuthenticationChange={onUserPhoneAuthenticationChange}
        getWalletAuth={getWalletAuth}
        onGetWalletAuthChange={onGetWalletAuthChange}
      />
    </ModalBody>
  );
};
