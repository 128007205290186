/* eslint-disable no-control-regex */
import { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { UncontrolledTooltip } from "reactstrap";
import CodeEditor from "@uiw/react-textarea-code-editor";
import clx from "classnames";
import { withFeature } from "../../../../../../hoc";
import { CUSTOM_CSS_FEATURE } from "../../../../../../constants";
import { PlanTag } from "../../../../../../components";

export const CustomCssTextArea = withFeature(
  ({ onChange, value, isDisabled }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const onClick = () => setIsExpanded((prevState) => !prevState);
    const [remainingChars, setRemainingChars] = useState(2048);
    const textAreaRef = useRef(null);
    useEffect(() => {
      setRemainingChars(2048 - value.length);
    }, [value.length]);

    const onChangeHandler = (val = "") => {
      // The editor adds a non-ascii character for space, so we remove any non-ascii characters before setting the value
      onChange(val.replace(/[^\x00-\x7F]/g, ""));
    };

    const onContainerClick = () => {
      textAreaRef?.current?.focus();
    };
    return (
      <div className={styles.custom_css_field_container}>
        <div className={clx("label-wrapper")}>
          <label htmlFor="customCss">Custom CSS</label>
          <AiOutlineQuestionCircle id={`tooltip-custom-css`} />
          <UncontrolledTooltip
            popperClassName="tooltip-popper"
            target={`tooltip-custom-css`}
            placement="top"
            autohide={false}
          >
            Pixel-perfect the UI by providing a custom CSS for the UI elements.
            Please refer to the documentation at{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.metakeep.xyz/docs/user-wallet-custom-css"
            >
              https://docs.metakeep.xyz/docs/user-wallet-custom-css
            </a>{" "}
            for the list of supported CSS properties and selectors.
          </UncontrolledTooltip>
          <PlanTag locked={isDisabled} id="custom-css" />
          <span onClick={onClick} className={styles.custom_css_toggler}>
            {isExpanded ? "Collapse" : "Expand"}
          </span>
        </div>
        <div
          className={clx(styles.custom_css_wrap, isExpanded && styles.expanded)}
          onClick={onContainerClick}
        >
          <CodeEditor
            disabled={isDisabled}
            ref={textAreaRef}
            value={value}
            language="css"
            maxLength={2048}
            placeholder="Enter your custom css here"
            onChange={(evn) => onChangeHandler(evn.target.value)}
            style={{
              fontSize: 15,
              backgroundColor: "#fcfcfc",
              borderRadius: "8px",
            }}
          />
          {isExpanded && (
            <span className={styles.remaining_chars}>
              {remainingChars} Remaining
            </span>
          )}
        </div>
      </div>
    );
  },
  CUSTOM_CSS_FEATURE
);
